export const environment = {
	production: false,
	account: '001i000001tzCcu',
	accountKia: '001i000001tzCcu',
	accountBella: '001i000001tzCcu',
	accountHonda: '001i000001tzCcu',
	accountAcura: '001i000001tzCcu',
	accountMazda: '001i000001tzCcu',
	accountNissan: '001i000001tzCcu',
	account399: '001i000001tzCcu',
	accountHyundai: '001i000001tzCcu',

	salesApiLink: 'https://helios-sales-api-eb.apps-connectassistance.com',
	//salesApiLink: 'http://localhost:3000',
	apiLink: 'https://helios-api-quality.herokuapp.com',
	// apiLink: 'http://localhost:3000',
	athMovilLink: '/#/ath_movil_staging/?id=',
	stripeKey:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPR:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPA: 'pk_test_80VLmgp51XNdQID9pO1qFq6a',
	stripeKeyCR:
		'pk_test_51Gv9rELGDymA3BfKBjBynowDjKtzIPWq4utKtNnMEkGdJrvkHnckLTIzZFvIbCoVTyvyOIrDSw5bbcEcFcJz7GfP0000yRpvK3',
	stripeKeyCO:
		'pk_test_51CwGaHA0iubLCAyqNCojfDXNctsEls26kkoX6dy1fJnP4T3eH1Dz8TwBS0jzoUTGkykOdptc8gnLtKr6TlKVJ3mM00o1fXHvVs',
	accountDefault: 'Dev Test Account',
	stripeDashboard: 'https://dashboard.stripe.com/test/customers/',
	athMovilSandbox: true,
};
