import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SalesModel } from './models/SalesModel';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	constructor(public cart: SalesModel) {}
	inputs: SalesModel;

	ngOnInit(): void {
		this.cart.inputs.campaign = environment.accountDefault;
	}
}
