import { ActionReducerMap } from '@ngrx/store';
import { salesReducer } from 'src/app/components/sales/store/reducers/sales.reducer';
import { ISalesInfo } from '../../components/sales/models/sales.interface';
export interface IAppState {
	salesReducer: ISalesInfo;
}

export const AppRootReducer: ActionReducerMap<IAppState> = {
	salesReducer,
};
