import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { User } from '../models/User';

@Injectable()
export class AuthenticationService {
	constructor(private storage: LocalStorageService) {}

	storeAuth(token: String, expires: number, user: User) {
		this.storage.store('token', token);
		this.storage.store('expires', expires);
		this.storage.store('user', user);
	}

	getAuthToken() {
		return this.storage.retrieve('token');
	}

	isTokenExpired() {
		return Date.now() > this.storage.retrieve('expires');
	}
}
