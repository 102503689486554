import { Injectable } from '@angular/core';

@Injectable()
export class SalesModel {
	public inputs = {
		campaign: '',
		accountId: '',
		numVehiculos: 0,
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		make1: '',
		model1: '',
		address1: '',
		address2: '',
		city: '',
		year1: 0,
		color1: '',
		plate1: '',
		otro1: '',
		make2: '',
		model2: '',
		year2: 0,
		color2: '',
		plate2: '',
		otro2: '',
		make3: '',
		model3: '',
		year3: 0,
		color3: '',
		plate3: '',
		otro3: '',
		make4: '',
		model4: '',
		year4: 0,
		color4: '',
		plate4: '',
		otro4: '',
		make5: '',
		model5: '',
		year5: 0,
		color5: '',
		plate5: '',
		otro5: '',
		subscription: '',
		cFirstName: '',
		cLastName: '',
		cCard: '',
		cCCV: '',
		cMonth: '',
		cYear: '',
		rateMensual: '',
		rateAnual: '',
		zipCode: '',
		autoMake1: '',
		autoMake2: '',
		autoMake3: '',
		autoMake4: '',
		autoMake5: '',
		autoModel1: '',
		autoModel2: '',
		autoModel3: '',
		autoModel4: '',
		autoModel5: '',
		autoYear1: '',
		autoYear2: '',
		autoYear3: '',
		autoYear4: '',
		autoYear5: '',
		autoColor1: '',
		autoColor2: '',
		autoColor3: '',
		autoColor4: '',
		autoColor5: '',
		autoPlate1: '',
		autoPlate2: '',
		autoPlate3: '',
		autoPlate4: '',
		autoPlate5: '',
		autoVin1: '',
		autoVin2: '',
		autoVin3: '',
		autoVin4: '',
		autoVin5: '',
		autoOtro1: '',
		autoOtro2: '',
		autoOtro3: '',
		autoOtro4: '',
		autoOtro5: '',
		motoraMake1: '',
		motoraMake2: '',
		motoraMake3: '',
		motoraMake4: '',
		motoraMake5: '',
		motoraModel1: '',
		motoraModel2: '',
		motoraModel3: '',
		motoraModel4: '',
		motoraModel5: '',
		motoraYear1: '',
		motoraYear2: '',
		motoraYear3: '',
		motoraYear4: '',
		motoraYear5: '',
		motoraColor1: '',
		motoraColor2: '',
		motoraColor3: '',
		motoraColor4: '',
		motoraColor5: '',
		motoraPlate1: '',
		motoraPlate2: '',
		motoraPlate3: '',
		motoraPlate4: '',
		motoraPlate5: '',
		motoraVin1: '',
		motoraVin2: '',
		motoraVin3: '',
		motoraVin4: '',
		motoraVin5: '',
		motoraOtro1: '',
		motoraOtro2: '',
		motoraOtro3: '',
		motoraOtro4: '',
		motoraOtro5: '',
		homeAddress11: '',
		homeAddress12: '',
		homeAddress13: '',
		homeAddress14: '',
		homeAddress15: '',
		homeAddress21: '',
		homeAddress22: '',
		homeAddress23: '',
		homeAddress24: '',
		homeAddress25: '',
		homeZipCode1: '',
		homeZipCode2: '',
		homeZipCode3: '',
		homeZipCode4: '',
		homeZipCode5: '',
		homeCity1: '',
		homeCity2: '',
		homeCity3: '',
		homeCity4: '',
		homeCity5: '',

		subscriptionAmount: 0,
		name_payment: '',
		lastname_payment: '',
		email_payment: '',
		address_payment: '',
		zipcode_payment: '',
		city_payment: '',
		customerIdStripe: '',
		subscriptionIdStripe: '',
		cardIdStripe: '',
		cardNum: '',
		cardMonth: '',
		cardYear: '',
		cardCCV: '',

		autoMakeAdd: '',
		autoModelAdd: '',
		autoYearAdd: '',
		autoColorAdd: '',
		autoPlateAdd: '',
		autoVinAdd: '',
		autoOtroAdd: '',

		motoraMakeAdd: '',
		motoraModelAdd: '',
		motoraYearAdd: '',
		motoraColorAdd: '',
		motoraPlateAdd: '',
		motoraVinAdd: '',
		motoraOtroAdd: '',

		homeAddress1Add: '',
		homeAddress2Add: '',
		homeZipCodeAdd: '',
		homeCityAdd: '',

		autoSelected: 0,
		motoraSelected: 0,
		hogarSelected: 0,

		createdDate: '',
		make: '',
		model: '',

		recurringDateDay: '', // dejar en minúscula
		amount: 0,

		monthlyOrAnnual: '',
		plan: '',

		paymentDay: '',

		autoOther1: false,
		autoOther2: false,
		autoOther3: false,
		autoOther4: false,
		autoOther5: false,
		autoOtherAdd: false,
		motoraOther1: false,
		motoraOther2: false,
		motoraOther3: false,
		motoraOther4: false,
		motoraOther5: false,
		motoraOtherAdd: false,

		autoModelIfOther1: '',
		autoModelIfOther2: '',
		autoModelIfOther3: '',
		autoModelIfOther4: '',
		autoModelIfOther5: '',

		motoraMakeIfOther1: '',
		motoraMakeIfOther2: '',
		motoraMakeIfOther3: '',
		motoraMakeIfOther4: '',
		motoraMakeIfOther5: '',

		agentName: '',
		paymentCount: 0,

		active: false,

		status: '',

		mongoSubId: '',

		logMessage: '',

		agentId: '',

		sfClientId: 0,

		isCashPayment: false,

		autoStatus1: '',
		autoStatus2: '',
		autoStatus3: '',
		autoStatus4: '',
		autoStatus5: '',
		motorcycleStatus1: '',
		motorcycleStatus2: '',
		motorcycleStatus3: '',
		motorcycleStatus4: '',
		motorcycleStatus5: '',
		homeStatus1: '',
		homeStatus2: '',
		homeStatus3: '',
		homeStatus4: '',
		homeStatus5: '',
		sourceType: '',
		hasTrial: true,
		paymentType: '',
	};

	public clear() {
		this.inputs.firstName = '';
		this.inputs.lastName = '';
		this.inputs.email = '';
		this.inputs.phone = '';
		this.inputs.autoYear1 = '';
		this.inputs.autoYear2 = '';
		this.inputs.autoYear3 = '';
		this.inputs.autoYear4 = '';
		this.inputs.autoYear5 = '';
		this.inputs.autoMake1 = '';
		this.inputs.autoMake2 = '';
		this.inputs.autoMake3 = '';
		this.inputs.autoMake4 = '';
		this.inputs.autoMake5 = '';
		this.inputs.autoModel1 = '';
		this.inputs.autoModel2 = '';
		this.inputs.autoModel3 = '';
		this.inputs.autoModel4 = '';
		this.inputs.autoModel5 = '';
		this.inputs.autoColor1 = '';
		this.inputs.autoColor2 = '';
		this.inputs.autoColor3 = '';
		this.inputs.autoColor4 = '';
		this.inputs.autoColor5 = '';
		this.inputs.autoPlate1 = '';
		this.inputs.autoPlate2 = '';
		this.inputs.autoPlate3 = '';
		this.inputs.autoPlate4 = '';
		this.inputs.autoPlate5 = '';

		this.inputs.motoraMake1 = '';
		this.inputs.motoraMake2 = '';
		this.inputs.motoraMake3 = '';
		this.inputs.motoraMake4 = '';
		this.inputs.motoraMake5 = '';
		this.inputs.motoraModel1 = '';
		this.inputs.motoraModel2 = '';
		this.inputs.motoraModel3 = '';
		this.inputs.motoraModel4 = '';
		this.inputs.motoraModel5 = '';
		this.inputs.motoraColor1 = '';
		this.inputs.motoraColor2 = '';
		this.inputs.motoraColor3 = '';
		this.inputs.motoraColor4 = '';
		this.inputs.motoraColor5 = '';
		this.inputs.motoraPlate1 = '';
		this.inputs.motoraPlate2 = '';
		this.inputs.motoraPlate3 = '';
		this.inputs.motoraPlate4 = '';
		this.inputs.motoraPlate5 = '';

		this.inputs.motoraYear1 = '';
		this.inputs.motoraYear2 = '';
		this.inputs.motoraYear3 = '';
		this.inputs.motoraYear4 = '';
		this.inputs.motoraYear5 = '';
		this.inputs.homeAddress11 = '';
		this.inputs.homeAddress12 = '';
		this.inputs.homeAddress13 = '';
		this.inputs.homeAddress14 = '';
		this.inputs.homeAddress15 = '';
		this.inputs.homeAddress21 = '';
		this.inputs.homeAddress22 = '';
		this.inputs.homeAddress23 = '';
		this.inputs.homeAddress24 = '';
		this.inputs.homeAddress25 = '';
		this.inputs.homeZipCode1 = '';
		this.inputs.homeZipCode2 = '';
		this.inputs.homeZipCode3 = '';
		this.inputs.homeZipCode4 = '';
		this.inputs.homeZipCode5 = '';
		this.inputs.homeCity1 = '';
		this.inputs.homeCity2 = '';
		this.inputs.homeCity3 = '';
		this.inputs.homeCity4 = '';
		this.inputs.homeCity5 = '';
		this.inputs.monthlyOrAnnual = '';
		this.inputs.mongoSubId = '';
		this.inputs.customerIdStripe = '';
		this.inputs.subscriptionIdStripe = '';
		this.inputs.cardIdStripe = '';
		// this.inputs.amount = '';
		this.inputs.plan = '';
		this.inputs.autoSelected = 1;
		this.inputs.motoraSelected = 0;
		this.inputs.hogarSelected = 0;
		this.inputs.paymentDay = '';
		this.inputs.logMessage = 'Created a new subscription.';
		this.inputs.status = '';
		// this.inputs.selectedPlan = '';
		// this.inputs.mensualAuto = 0;
		// this.inputs.mensualMotora = 0;
		// this.inputs.mensualHogar = 0;
		// this.inputs.anualAuto = 0;
		// this.inputs.anualMotora = 0;
		// this.inputs.anualHogar = 0;
		// this.inputs.authorizePromosInSale = true;
	}

	public clearPersonal() {
		this.inputs.firstName = '';
		this.inputs.lastName = '';
		this.inputs.email = '';
		this.inputs.phone = '';
		this.inputs.name_payment = '';
		this.inputs.cardNum = '';
		this.inputs.cardMonth = '';
		this.inputs.cardYear = '';
		this.inputs.cardCCV = '';
	}

	public clearArticles() {
		this.inputs.autoYear1 = '';
		this.inputs.autoYear2 = '';
		this.inputs.autoYear3 = '';
		this.inputs.autoYear4 = '';
		this.inputs.autoYear5 = '';
		this.inputs.autoMake1 = '';
		this.inputs.autoMake2 = '';
		this.inputs.autoMake3 = '';
		this.inputs.autoMake4 = '';
		this.inputs.autoMake5 = '';
		this.inputs.autoModel1 = '';
		this.inputs.autoModel2 = '';
		this.inputs.autoModel3 = '';
		this.inputs.autoModel4 = '';
		this.inputs.autoModel5 = '';
		this.inputs.autoColor1 = '';
		this.inputs.autoColor2 = '';
		this.inputs.autoColor3 = '';
		this.inputs.autoColor4 = '';
		this.inputs.autoColor5 = '';
		this.inputs.autoPlate1 = '';
		this.inputs.autoPlate2 = '';
		this.inputs.autoPlate3 = '';
		this.inputs.autoPlate4 = '';
		this.inputs.autoPlate5 = '';

		this.inputs.motoraYear1 = '';
		this.inputs.motoraYear2 = '';
		this.inputs.motoraYear3 = '';
		this.inputs.motoraYear4 = '';
		this.inputs.motoraYear5 = '';
		this.inputs.motoraMake1 = '';
		this.inputs.motoraMake2 = '';
		this.inputs.motoraMake3 = '';
		this.inputs.motoraMake4 = '';
		this.inputs.motoraMake5 = '';
		this.inputs.motoraModel1 = '';
		this.inputs.motoraModel2 = '';
		this.inputs.motoraModel3 = '';
		this.inputs.motoraModel4 = '';
		this.inputs.motoraModel5 = '';
		this.inputs.motoraColor1 = '';
		this.inputs.motoraColor2 = '';
		this.inputs.motoraColor3 = '';
		this.inputs.motoraColor4 = '';
		this.inputs.motoraColor5 = '';
		this.inputs.motoraPlate1 = '';
		this.inputs.motoraPlate2 = '';
		this.inputs.motoraPlate3 = '';
		this.inputs.motoraPlate4 = '';
		this.inputs.motoraPlate5 = '';

		this.inputs.homeAddress11 = '';
		this.inputs.homeAddress12 = '';
		this.inputs.homeAddress13 = '';
		this.inputs.homeAddress14 = '';
		this.inputs.homeAddress15 = '';
		this.inputs.homeAddress21 = '';
		this.inputs.homeAddress22 = '';
		this.inputs.homeAddress23 = '';
		this.inputs.homeAddress24 = '';
		this.inputs.homeAddress25 = '';
		this.inputs.homeZipCode1 = '';
		this.inputs.homeZipCode2 = '';
		this.inputs.homeZipCode3 = '';
		this.inputs.homeZipCode4 = '';
		this.inputs.homeZipCode5 = '';
		this.inputs.homeCity1 = '';
		this.inputs.homeCity2 = '';
		this.inputs.homeCity3 = '';
		this.inputs.homeCity4 = '';
		this.inputs.homeCity5 = '';
		this.inputs.autoStatus1 = '';
		this.inputs.autoStatus2 = '';
		this.inputs.autoStatus3 = '';
		this.inputs.autoStatus4 = '';
		this.inputs.autoStatus5 = '';
		this.inputs.motorcycleStatus1 = '';
		this.inputs.motorcycleStatus2 = '';
		this.inputs.motorcycleStatus3 = '';
		this.inputs.motorcycleStatus4 = '';
		this.inputs.motorcycleStatus5 = '';
		this.inputs.homeStatus1 = '';
		this.inputs.homeStatus2 = '';
		this.inputs.homeStatus3 = '';
		this.inputs.homeStatus4 = '';
		this.inputs.homeStatus5 = '';
	}

	public clearPayment() {
		this.inputs.name_payment = '';
		this.inputs.cardNum = '';
		this.inputs.cardMonth = '';
		this.inputs.cardYear = '';
		this.inputs.cardCCV = '';
	}
}
