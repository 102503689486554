import { Action } from '@ngrx/store';
import { ISalesInfo } from '../../models/sales.interface';
import { ActionSales } from '../actions/sales.actions';
import { initialSalesReducer } from '../state/sales.state';

interface ISalesReducerItems {
	data: ISalesInfo;
}

interface ISalesAction extends Action, ISalesReducerItems {
	data: ISalesInfo;
	type: string;
}

export function salesReducer(state = initialSalesReducer, action: ISalesAction) {
	switch (action.type) {
		case ActionSales.SaveGeneralInfo:
			return {
				...state,
				general_information: {
					...action.data,
				},
			};
		case ActionSales.SavePersonalInfo:
			return {
				...state,
				...action.data,
			};
		case ActionSales.SaveAutosInfo:
			return {
				...state,
				vehicles_information: {
					...state.vehicles_information,
					autos: {
						...state.vehicles_information.autos,
						...action.data,
					},
				},
			};
		case ActionSales.SaveMotorcycleInfo:
			return {
				...state,
				vehicles_information: {
					...state.vehicles_information,
					motorcycles: {
						...state.vehicles_information.motorcycles,
						...action.data,
					},
				},
			};
		case ActionSales.SaveHomesInfo:
			return {
				...state,
				vehicles_information: {
					...state.vehicles_information,
					homes: {
						...state.vehicles_information.homes,
						...action.data,
					},
				},
			};
		case ActionSales.SavePaymentTerm:
			return {
				...state,
				vehicles_information: {
					...state.vehicles_information,
					payment_term: action.data,
				},
			};
		case ActionSales.SavePaymentInfo:
			return {
				...state,
				payment_information: {
					...action.data,
				},
			};
		case ActionSales.ResetVehiclesAndPaymentInfo:
			return {
				...state,
				vehicles_information: { ...initialSalesReducer.vehicles_information },
				payment_information: null,
			};
		case ActionSales.ResetInfo:
			return { ...initialSalesReducer };
		default:
			return state;
	}
}
