import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'rb-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
