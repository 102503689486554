export const environment = {
	production: true,
	account: '0011Y00002Mtuxw',
	accountKia: '001i000001Ar9h9',
	accountBella: '001i000002CBhli',
	accountHonda: '001i000002CBh8b',
	accountAcura: '001i000002CBh9k',
	accountMazda: '0011Y00002Fnf0X',
	accountNissan: '0011Y00002KQPfn',
	account399: '001i000001JXFFo',
	accountHyundai: '0014z00001dKb4T',
	apiLink: 'https://helios-api-prod.herokuapp.com',
	salesApiLink: 'https://helios-sales-api-prod.herokuapp.com',
	athMovilLink: 'https://sales.cra.pr/ath_movil/?id=',
	stripeKey:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPR:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPA:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyCR:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyCO:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	accountDefault: 'Connect Assistance',
	stripeDashboard: 'https://dashboard.stripe.com/customers/',
	athMovilSandbox: false,
};
