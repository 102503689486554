import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { salesFeatureName } from 'src/app/components/sales/store/state/sales.state';
import { IAppState } from '../reducers/index.app.reducer';

/** reducers name to persist **/
const config: string[] = [salesFeatureName];

/** reducers to persist **/
export function hydrationMetaReducer(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
	return (state, action) => {
		const nextState = reducer(state, action);
		const valores = {};
		let exist = false;
		for (const key in sessionStorage) {
			if (key.includes('state-helios-') === true && sessionStorage.getItem(key) !== null) {
				valores[key.slice(13)] = JSON.parse(sessionStorage.getItem(key));
				exist = true;
			}
		}

		if (action.type === INIT) {
			if (exist) {
				try {
					return { ...nextState, ...valores };
				} catch {
					sessionStorage.removeItem('state-helios-');
					for (const key in sessionStorage) {
						if (key.includes('state-helios-') && sessionStorage.getItem(key) !== null) {
							sessionStorage.removeItem(key);
						}
					}
				}
			}
		}
		config.forEach(value => {
			if (value in nextState) {
				const valor = JSON.stringify(nextState[value]);
				sessionStorage.setItem('state-helios-' + value, valor);
			}
		});
		return nextState;
	};
}
