import { ISalesInfo } from '../../models/sales.interface';

export const salesFeatureName = 'salesReducer';

export const initialSalesReducer: ISalesInfo = {
	personal_information: null,
	vehicles_information: { autos: null, homes: null, motorcycles: null, payment_term: null },
	payment_information: null,
	general_information: null,
};
