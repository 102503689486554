import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppFacade } from '../store/facade/app.facade';
import { AuthenticationService } from './../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private auth: AuthenticationService, private appFacade: AppFacade) {}

	canActivate() {
		if (!this.auth.isTokenExpired()) {
			return true;
		}
		this.router.navigate(['/login']);
		this.appFacade.resetDataStore();
		return false;
	}
}
