import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appActions from '../actions/app.actions';
import { IAppState } from '../reducers/index.app.reducer';

@Injectable()
export class AppFacade {
	constructor(private store: Store<IAppState>) {}

	public resetDataStore() {
		this.store.dispatch(appActions.resetDataStore());
	}
}
