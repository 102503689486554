import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AppFacade } from 'src/app/store/facade/app.facade';

@Component({
	selector: 'rb-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
	public agentName: boolean;
	public agentId: boolean;
	public agentUsersAccess: boolean;
	public agentLogsAccess: boolean;
	public agentDispatchAccess: boolean;
	public agentDashboardAccess: boolean;
	public agentSalesAccess: boolean;
	public agentSubscriptionsAccess: boolean;

	public homeDropdown: boolean = false;
	public newMenu: boolean = true;

	public showMenu: boolean = true;
	public poNumber: string;

	public dispatchViewFix: string = 'unset';
	public currentRoute: string = '';
	public previousRoute: string = '';

	constructor(
		public router: Router,
		public storage: LocalStorageService,
		public route: ActivatedRoute,
		private appFacade: AppFacade
	) {}

	ngOnInit(): void {
		this.router.events.subscribe((val: Event) => {
			if (val instanceof NavigationEnd) {
				this.previousRoute = this.currentRoute;
				this.currentRoute = val.url;

				if (this.previousRoute.toLowerCase().includes('login')) {
					this.getAccesses();
				}

				if (
					this.router.url.toLowerCase().includes('login') ||
					this.router.url.toLowerCase().includes('resetpassword')
				) {
					this.showMenu = false;
				} else this.showMenu = true;
				this.homeDropdown = false;
				if (this.router.url.includes('dispatch')) this.dispatchViewFix = 'fixed';
				else this.dispatchViewFix = 'unset';
			}
		});

		this.getAccesses();
	}

	public getAccesses() {
		this.agentName = this.storage.retrieve('employeeName');
		this.agentId = this.storage.retrieve('_id');

		this.agentUsersAccess = this.storage.retrieve('agentUsersAccess');
		this.agentLogsAccess = this.storage.retrieve('agentLogsAccess');
		this.agentDispatchAccess = this.storage.retrieve('agentDispatchAccess');
		this.agentDashboardAccess = this.storage.retrieve('agentDashboardAccess');
		this.agentSalesAccess = this.storage.retrieve('agentSalesAccess');
		this.agentSubscriptionsAccess = this.storage.retrieve('agentSubscriptionsAccess');
	}
	public logoutClick() {
		this.storage.clear();
		this.router.navigate(['/login']);
		this.appFacade.resetDataStore();
	}

	searchService() {
		console.log('##Input', this.poNumber);
		this.router.navigate(['/service', this.poNumber]);
		this.poNumber = '';
	}
}
