import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@environment';
import { ActionReducerMap } from '@ngrx/store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { AuthGuard } from './guards/authguard';
import { SalesModel } from './models/SalesModel';
import { AuthenticationService } from './services/auth.service';
import { HttpService } from './services/http.service';
import { AppFacade } from './store/facade/app.facade';
import { metaReducers } from './store/hydration/index.hydration';
import { AppRootReducer, IAppState } from './store/reducers/index.app.reducer';

export const AppRootReducerToken = new InjectionToken<ActionReducerMap<IAppState>>('Feature App Component');
@NgModule({
	declarations: [AppComponent, MenuComponent, FooterComponent],
	imports: [
		FormsModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		ToastrModule.forRoot(),
		NgxWebstorageModule.forRoot(),
		RouterModule.forRoot(
			[
				{ path: 'login', loadChildren: './components/login/index#LoginModule' },
				{ path: 'sales', loadChildren: './components/sales/index#SalesModule', canActivate: [AuthGuard] },
				{
					path: 'athmovil',
					loadChildren: './components/athmovil/athmovil.module#AthmovilModule',
					canActivate: [AuthGuard],
				},
				{
					path: 'subscriptions',
					loadChildren: './components/subscriptions/index#SubscriptionsModule',
					canActivate: [AuthGuard],
				},
				{ path: 'noaccess', loadChildren: './components/noaccess/index#NoaccessModule', canActivate: [AuthGuard] },
				{ path: '', pathMatch: 'full', redirectTo: 'subscriptions' },
				{ path: '**', pathMatch: 'full', redirectTo: 'subscriptions' },
			],
			{ enableTracing: false, onSameUrlNavigation: 'reload' }
		),
		StoreModule.forRoot(AppRootReducerToken, { metaReducers }),
		StoreDevtoolsModule.instrument({
			logOnly: environment.production,
			maxAge: 25,
		}),
	],
	providers: [
		AuthGuard,
		AuthenticationService,
		HttpService,
		SalesModel,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: AppRootReducerToken,
			useValue: AppRootReducer,
		},
		AppFacade,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
