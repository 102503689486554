import { createAction } from '@ngrx/store';
import { ISalesInfo } from '../../models/sales.interface';

export enum ActionSales {
	SaveGeneralInfo = '[Sales Component] Save general info',
	SavePersonalInfo = '[Sales Component] Save personal info',
	SaveAutosInfo = '[Sales Component] Save autos info',
	SaveMotorcycleInfo = '[Sales Component] Save motorcylce info',
	SaveHomesInfo = '[Sales Component] Save home info',
	SavePaymentInfo = '[Sales Component] Save payment info',
	SavePaymentTerm = '[Sales Component] Save payment term info',
	ResetVehiclesAndPaymentInfo = '[Sales Component] Reset payment and vehicles info',
	ResetInfo = '[Sales Component] Reset info',
}
export const salesSaveGeneralInfo = createAction(ActionSales.SaveGeneralInfo, (data: {}) => ({ data }));
export const salesSavePersonalInfo = createAction(ActionSales.SavePersonalInfo, (data: ISalesInfo) => ({ data }));
export const salesSaveAutosInfo = createAction(ActionSales.SaveAutosInfo, (data: {}) => ({ data }));
export const salesSaveMotorcycleInfo = createAction(ActionSales.SaveMotorcycleInfo, (data: {}) => ({ data }));
export const salesSaveHomeInfo = createAction(ActionSales.SaveHomesInfo, (data: {}) => ({ data }));
export const salesSavePaymentInfo = createAction(ActionSales.SavePaymentInfo, (data: {}) => ({ data }));
export const salesSavePaymentTerm = createAction(ActionSales.SavePaymentTerm, (data: string) => ({ data }));
export const salesVehiclesAndPaymentResetInfo = createAction(ActionSales.ResetVehiclesAndPaymentInfo);
export const salesResetInfo = createAction(ActionSales.ResetInfo);
